<template>
	<el-dialog title="材料附件" :visible.sync="showVisible" width="800px" center :close-on-click-modal="false">
		<Table
			:table-data="tableData"
			:table-head="tableHead"
			:is-show-selection="false"
			:isShowPage="false"
			:showOperation="true"
			:tableHeight="tableHeight"
			:operation-column-width="80"
		>
			<template #operation="{ scope }">
				<i class="el-icon-delete" @click="delData(scope.row)"></i>
			</template>
		</Table>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			showVisible: false,
			tableHead: [
				{
					label: '附件名称',
					prop: 'title',
					formatter: (row) => {
						return row.title || '-';
					},
				},
				{
					label: '上传时间',
					prop: 'createTime',
					width: 160,
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
				{
					label: '附件大小',
					prop: 'byteCount',
					formatter: (row) => {
						return row.byteCount && row.byteCount > 0 ? (row.byteCount / 1024 / 1024).toFixed(2) + 'MB' : 0;
					},
				},
				{
					label: '课时',
					prop: 'description',
					formatter: (row) => {
						return row.description || '-';
					},
				},
			],
			tableData: [],
			link: '',
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	methods: {
		init(data) {
			this.link = data._links.load.href;
			this.getTabledata(this.link);
		},
		getTabledata(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection[0];
						this.showVisible = true;
						let fileList = collection.attachmentMap;
						if (!fileList) return (this.tableData = []);
						if (fileList['DOCUMENT'] && !fileList['VIDEO']) {
							this.tableData = [...fileList['DOCUMENT']];
						} else if (fileList['VIDEO'] && !fileList['DOCUMENT']) {
							this.tableData = [...fileList['VIDEO']];
						} else if (!fileList['DOCUMENT'] && !fileList['VIDEO']) {
							this.tableData = [];
						} else {
							this.tableData = [...fileList['DOCUMENT'], ...fileList['VIDEO']];
						}
						console.log(this.tableData);
					}
				})
				.catch((e) => {});
		},
		delData(row) {
			this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.$http
					.delete(this.api.delAttachments + '/' + row.id + '.json')
					.then((res) => {
						if (res.data && res.data.success) {
							this.getTabledata(this.link);
							this.$message({
								message: '删除成功',
								type: 'success',
							});
							this.$emit('getList');
						}
					})
					.catch((e) => {});
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .el-table {
	margin-bottom: 20px;
}
/deep/ .el-icon-delete {
	cursor: pointer;
}
</style>

